<template>
  <div>
    <ui-dialog
      v-model="computedValue"
      class="z-30"
      title="検収"
    >
      <template #content>
        <div class="text-left">
          <p class="text-sub text-xs ml-1 mb-1 mt-4">
            衣装単体写真の登録（任意）
          </p>
          <div
            v-if="uploading"
            class="rounded-xl w-full h-48 text-center flex justify-center items-center bg-gray-900 bg-opacity-50 mb-2"
          >
            <font-awesome-icon
              :icon="['fas', 'circle-notch']"
              class="text-sub text-3xl"
              spin
            />
          </div>
          <img
            v-else-if="additionalInfo.thumbnail"
            :src="`/uploads/merchandises/thumbnail/${additionalInfo.thumbnail}`"
            class="rounded-xl w-full h-48 object-contain shadow-xl mb-2"
          >
          <div
            v-else
            class="rounded-xl w-full h-48 text-center flex justify-center items-center bg-gray-900 bg-opacity-50 mb-2"
          >
            <div class="text-sub text-opacity-50">
              画像未登録
            </div>
          </div>
          <ui-file-upload
            target="merchandises"
            target-id="only"
            class="mt-2"
            button-class="w-full text-sm"
            @upload-begin="uploading = true"
            @upload-ok="additionalInfo.thumbnail = $event.thumbnail; additionalInfo.original = $event.original;"
            @upload-end="uploading = false"
          >
            <font-awesome-icon
              v-if="uploading"
              :icon="['fas', 'circle-notch']"
              spin
            />
            <template v-else>
              アップロード
            </template>
          </ui-file-upload>
        </div>
      </template>
      <ui-button
        color="primary"
        class="mb-2"
        @click="submit"
      >
        物販に登録
      </ui-button>
      <ui-button
        class="mb-2"
        color="secondary"
        @click="computedValue = false"
      >
        キャンセル
      </ui-button>
    </ui-dialog>
  </div>
</template>

<script>
export default {
  name: 'MerchandiseOrderAcceptanceDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      newValue: this.value,
      uploading: false,
      additionalInfo: {
        original: '',
        thumbnail: '',
      },
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue;
      },
      set(value) {
        this.newValue = value;
        this.$emit('input', value);
      },
    },
  },
  watch: {
    async value(value) {
      this.newValue = value;
      if (!value) {
        this.additionalInfo.thumbnail = '';
        this.additionalInfo.original = '';
      }
    },
  },
  methods: {
    submit() {
      this.$emit('submit', { ...this.additionalInfo });
    },
  },
};
</script>
