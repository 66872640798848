<template>
  <div>
    <ui-dialog
      v-model="computedValue"
      class="z-30"
      title="発注済み備品の登録"
    >
      <template #content>
        <div class="text-left">
          <ui-select
            v-model="selectedOrderTemplateId"
            :options="orderTemplateSelection"
            label="テンプレートを選択"
          />
          <ui-input
            v-model="editedItem.name"
            class="mt-4"
            label="項目名"
            placeholder="特徴を入力してください"
            :required="true"
          />
          <ui-number-input
            v-model="editedItem.stock"
            label="注文した数"
            :min="0"
            class="mt-4"
          />
          <ui-select
            v-model="editedItem.schedule_id"
            :options="schedules"
            class="mt-4"
            label="撮影を選択"
          />
          <ui-input
            v-model="editedItem.url"
            label="URL"
            class="mt-4 mb-4"
          />
        </div>
      </template>
      <ui-button
        color="primary"
        :loading="updating"
        :disabled="disabled"
        class="mb-2"
        @click="save"
      >
        登録
      </ui-button>
      <ui-button
        class="mb-2"
        @click="computedValue = false"
      >
        キャンセル
      </ui-button>
    </ui-dialog>
  </div>
</template>

<script>
import ScheduleService from '../../services/ScheduleService';
import EquipmentService from '../../services/EquipmentService';

export default {
  name: 'EquipmentEditDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newValue: this.value,
      orderTemplates: {},
      orderTemplateSelection: [],
      selectedOrderTemplateId: null,
      schedules: [],
      updating: false,
      editedItem: {
        name: '',
        schedule_id: null,
        stock: 1,
        url: '',
        equipment_category_id: null,
        equipment_place_id: null,
      },
      defaultEditedItem: {
        name: '',
        schedule_id: null,
        stock: 1,
        url: '',
        equipment_category_id: null,
        equipment_place_id: null,
      },
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue;
      },
      set(value) {
        this.newValue = value;
        this.$emit('input', value);
      },
    },
    disabled() {
      return !this.editedItem.name || !this.editedItem.stock;
    },
  },
  watch: {
    async value(value) {
      this.newValue = value;
      if (!value) {
        this.editedItem = { ...this.defaultEditedItem };
      }
    },
    selectedOrderTemplateId(value) {
      if (!value) {
        this.editedItem.equipment_place_id = null;
        this.editedItem.equipment_category_id = null;
        return;
      }
      const template = this.orderTemplates[value];
      this.editedItem.equipment_place_id = template.equipment_place_id;
      this.editedItem.equipment_category_id = template.equipment_category_id;
    },
  },
  async created() {
    const [orderTemplates, schedules] = await Promise.all([
      EquipmentService.getOrderTemplates(),
      ScheduleService.get('upcoming'),
    ]);
    this.orderTemplates = orderTemplates.reduce((p, c) => {
      // eslint-disable-next-line no-param-reassign
      p[c.id] = {
        equipment_place_id: c.equipment_place_id,
        equipment_category_id: c.equipment_category_id,
      };
      return p;
    }, {});
    this.orderTemplateSelection = [...orderTemplates, { id: null, name: '-- 未選択 --' }].map((item) => ({ value: item.id, text: item.name }));
    this.selectedOrderTemplateId = this.orderTemplateSelection[0].value;
    this.schedules = schedules.map((item) => ({ value: item.id, text: item.title }));
  },
  methods: {
    async save() {
      this.$emit('submit', { ...this.editedItem });
      this.computedValue = false;
    },
  },
};
</script>
