<template>
  <div>
    <ui-dialog
      v-model="computedValue"
      class="z-30"
      :title="orderMode ? '発注済み衣装の登録' : merchandiseId ? '衣装編集' : '衣装登録'"
    >
      <template #content>
        <div class="text-left">
          <div v-if="schedule">
            <div
              class="flex justify-between items-center px-2 py-3 my-4 border-gray-700 border-opacity-50 border-t border-b"
            >
              <div class="flex-grow">
                <p class="text-main">
                  {{ schedule.title }}
                </p>
                <p class="text-sub text-xxs">
                  {{ schedule.shooting_date | date }}
                </p>
              </div>
              <img
                v-if="schedule.thumbnail"
                v-lazy="`/uploads/schedules/${schedule.thumbnail}`"
                class="w-10 h-10 rounded-xl object-cover flex-shrink-0"
              />
              <img
                v-else
                v-lazy="`/uploads/actresses/${schedule.actresses[0].thumbnail}`"
                class="w-10 h-10 rounded-xl object-cover flex-shrink-0"
              />
            </div>
          </div>
          <div v-else>
            <ui-select
              v-model="editedItem.schedule_id"
              :options="schedules"
              label="撮影を選択"
              :required="true"
              @change="getActresses($event)"
            />
          </div>
          <ui-input
            v-model="editedItem.name"
            label="衣装名"
            placeholder="特徴を入力してください"
            class="mt-4"
            :required="true"
          />
          <p class="text-sub text-xs ml-1 mb-1 mt-4">
            着用する女優
          </p>
          <div v-if="schedule || actresses.length" class="grid grid-cols-2">
            <ui-radio
              v-for="actress in schedule ? schedule.actresses : actresses"
              :key="actress.id"
              v-model="editedItem.actress_id"
              :native-value="actress.id"
              name="merchandise-actress"
              :label="actress.name"
            />
          </div>
          <div v-else class="py-2 text-sub">
            スケジュールを選択してください
          </div>
          <ui-select
            v-model="editedItem.label_id"
            label="担当品番"
            class="mt-4"
            :options="labels"
          />
          <ui-input v-if="orderMode" v-model="url" label="URL" class="mb-4 mt-4" />
        </div>
      </template>
      <ui-button
        color="primary"
        :loading="updating"
        :disabled="disabled"
        class="mb-2"
        @click="save"
      >
        {{ merchandiseId ? '保存' : '登録' }}
      </ui-button>
      <ui-button class="mb-2" @click="computedValue = false">
        キャンセル
      </ui-button>
      <button
        v-if="merchandiseId"
        class="block focus:outline-none py-3 px-6 mt-2 mx-auto text-xs text-red-400"
        @click="deleteConfirmDialog = true"
      >
        この衣装を削除する
      </button>
    </ui-dialog>
    <ui-dialog
      v-if="merchandise"
      v-model="deleteConfirmDialog"
      title="削除確認"
      type="error"
      :description="`${merchandise.name}を削除してもよろしいですか？`"
      class="z-40"
    >
      <ui-button color="error" :loading="deleting" class="mb-2" @click="remove">
        削除する
      </ui-button>
      <ui-button @click="deleteConfirmDialog = false">
        キャンセル
      </ui-button>
    </ui-dialog>
  </div>
</template>

<script>
import { format, parse } from 'date-fns'
import LabelService from '../../services/LabelService'
import ScheduleService from '../../services/ScheduleService'
import MerchandiseService from '../../services/MerchandiseService'

export default {
  name: 'MerchandiseEditDialog',
  filters: {
    date(date) {
      return format(parse(date, 'yyyy-MM-dd', new Date()), 'yyyy年M月d日')
    },
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    merchandiseId: {
      type: [String, Number],
      default: 0,
    },
    scheduleId: {
      type: [String, Number],
      default: 0,
    },
    scheduleSelectable: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: undefined,
    },
    orderMode: {
      type: Boolean,
      default: false,
    },
    orderPreset: {
      type: Object,
      default: () => ({ scheduleId: null, actressId: null }),
    },
    orderItemId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      newValue: this.value,
      schedule: null,
      merchandise: null,
      labels: [],
      schedules: [],
      actresses: [],
      updating: false,
      editedItem: {
        name: '',
        schedule_id: 0,
        label_id: null,
        merchandise_category_id: 3,
        actress_id: 0,
      },
      url: '',
      deleteConfirmDialog: false,
      deleting: false,
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue
      },
      set(value) {
        this.newValue = value
        this.$emit('input', value)
      },
    },
    disabled() {
      return !this.editedItem.name || !this.editedItem.schedule_id || !this.editedItem.actress_id
    },
  },
  watch: {
    async value(value) {
      this.newValue = value
      if (!value) {
        this.editedItem.name = ''
        this.url = ''
      } else {
        if (!this.merchandiseId) return
        this.merchandise = await MerchandiseService.getOne(this.merchandiseId)
        this.editedItem.schedule_id = this.merchandise.schedule_id
        this.editedItem.name = this.merchandise.name
        this.editedItem.label_id = this.merchandise.label ? this.merchandise.label.id : null
        this.editedItem.actress_id = this.merchandise.actresses[0].id
      }
    },
    name(value) {
      if (value) {
        this.editedItem.name = value
      }
    },
    scheduleId: {
      immediate: true,
      async handler(value) {
        if (!value) return
        this.schedule = await ScheduleService.getOne(value)
        if (!this.merchandiseId) {
          this.editedItem.schedule_id = this.schedule.id
          this.editedItem.actress_id = this.schedule.actresses[0].id
        }
      },
    },
    orderPreset: {
      deep: true,
      immediate: true,
      async handler(value) {
        if (value.scheduleId) {
          await this.getActressesOnly(value.scheduleId)
          this.editedItem.schedule_id = value.scheduleId
        }
        if (value.actressId) {
          this.editedItem.actress_id = value.actressId
        }
      },
    },
  },
  async created() {
    const [labels, schedules] = await Promise.all([
      LabelService.get(),
      ScheduleService.get('upcoming'),
    ])
    this.labels = [{ id: null, name: 'ALL' }, ...labels].map(item => ({
      value: item.id,
      text: item.name,
    }))
    this.schedules = schedules.map(item => ({ value: item.id, text: item.title }))
  },
  methods: {
    async getActresses(id) {
      const schedule = await ScheduleService.getOne(id)
      this.actresses = schedule.actresses
      this.editedItem.actress_id = this.actresses[0].id
    },
    async getActressesOnly(id) {
      const schedule = await ScheduleService.getOne(id)
      this.actresses = schedule.actresses
    },
    async save() {
      if (this.orderMode) {
        this.$emit('submit', { ...this.editedItem, url: this.url })
        this.computedValue = false
        return
      }
      this.updating = true
      let id
      if (!this.merchandise) {
        id = await MerchandiseService.create({
          ...this.editedItem,
          order_item_id: this.orderItemId,
        })
      } else {
        id = this.merchandise.id
        await MerchandiseService.update(this.merchandise.id, { ...this.editedItem })
      }
      this.updating = false
      this.$store.dispatch('notification/set', {
        type: 'success',
        message: '衣装を登録しました！',
      })
      this.$emit('saved', id)
      this.computedValue = false
    },
    async remove() {
      this.deleting = true
      await MerchandiseService.delete(this.merchandise.id)
      this.deleting = false
      this.deleteConfirmDialog = false
      this.$store.dispatch('notification/set', {
        type: 'success',
        message: '衣装を削除しました！',
      })
      this.$emit('saved', this.merchandise.id)
      this.computedValue = false
    },
  },
}
</script>
