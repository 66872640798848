<template>
  <div>
    <h2 class="text-main font-bold text-xl mb-1">
      カテゴリ選択
    </h2>
    <div class="mt-4 grid grid-cols-2 gap-4">
      <button
        class="py-3 focus:outline-none bg-level-2 rounded-xl shadow-xl"
        @click="$emit('select-merchandise')"
      >
        <p class="text-pink-400">
          <icon-fashion class="h-9 mx-auto" />
        </p>
        <p class="text-pink-100 text-opacity-75 text-sm mt-2">
          物販用衣装
        </p>
      </button>
      <button
        class="py-3 focus:outline-none bg-level-2 rounded-xl shadow-xl"
        @click="$emit('select-equipment')"
      >
        <p class="text-blue-400">
          <icon-lego class="h-9 mx-auto" />
        </p>
        <p class="text-blue-100 text-opacity-75 text-sm mt-2">
          撮影用備品
        </p>
      </button>
    </div>
    <ui-button
      class="w-full mt-4"
      color="secondary"
      @click="$emit('cancel')"
    >
      閉じる
    </ui-button>
  </div>
</template>

<script>
export default {
  name: 'OrderAction',
};
</script>
