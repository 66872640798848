<template>
  <div>
    <ui-transition>
      <div v-if="!isLoading">
        <div v-if="orderItems.length">
          <order-item
            v-for="item in orderItems"
            :key="item.id"
            :item="item"
            @click-remove="confirmToRemoveOrder(item)"
            @click-acceptance="confirmToAcceptOrder(item)"
          />
        </div>
        <div
          v-else
          class="text-center text-sub text-opacity-75 py-6 bg-level2 text-sm"
        >
          処理が必要なアイテムはありません
        </div>
      </div>
    </ui-transition>
    <ui-action v-model="actionVisible">
      <order-action
        @select-merchandise="createMerchandiseOrder"
        @select-equipment="createEquipmentOrder"
        @cancel="actionVisible = false"
      />
    </ui-action>
    <ui-dialog
      v-model="deleteOrderConfirmDialog"
      title="発注登録を削除"
      type="error"
      :description="`「${deleteOrderTarget.name}」を削除してもよろしいですか？`"
      class="z-40"
    >
      <ui-button
        color="error"
        :loading="deletingOrder"
        class="mb-2"
        @click="removeOrder"
      >
        発注を削除する
      </ui-button>
      <ui-button @click="deleteOrderConfirmDialog = false; deleteOrderTarget.id = 0; deleteOrderTarget.name = '';">
        キャンセル
      </ui-button>
    </ui-dialog>
    <merchandise-edit-dialog
      v-model="addMerchandiseOrderDialog"
      :schedule-selectable="true"
      order-mode
      :order-preset="orderPreset"
      @submit="saveMerchandiseOrder($event)"
    />
    <equipment-edit-dialog
      v-model="addEquipmentOrderDialog"
      @submit="saveEquipmentOrder($event)"
    />
    <merchandise-order-acceptance-dialog
      v-model="acceptMerchandiseOrderConfirmDialog"
      :order="acceptMerchandiseOrderTarget"
      @submit="acceptMerchandiseOrder($event)"
    />
    <equipment-order-acceptance-dialog
      v-model="acceptEquipmentOrderConfirmDialog"
      :order="acceptEquipmentOrderTarget"
      @submit="acceptEquipmentOrder($event)"
    />
  </div>
</template>

<script>
// import { parse, format } from 'date-fns';
import OrderItem from '../components/order/OrderItem.vue';
import OrderAction from '../components/order/OrderAction.vue';
import MerchandiseEditDialog from '../components/merchandise/MerchandiseEditDialog.vue';
import EquipmentEditDialog from '../components/equipment/EquipmentEditDialog.vue';
import MerchandiseOrderAcceptanceDialog from '../components/order/MerchandiseOrderAcceptanceDialog.vue';
import EquipmentOrderAcceptanceDialog from '../components/order/EquipmentOrderAcceptanceDialog.vue';
import OrderService from '../services/OrderService';

export default {
  name: 'Order',
  components: {
    OrderItem,
    OrderAction,
    MerchandiseEditDialog,
    EquipmentEditDialog,
    MerchandiseOrderAcceptanceDialog,
    EquipmentOrderAcceptanceDialog,
  },
  data: () => ({
    isLoading: true,
    orderItems: [],
    actionVisible: false,
    addMerchandiseOrderDialog: false,
    addEquipmentOrderDialog: false,
    deleteOrderConfirmDialog: false,
    orderPreset: {
      scheduleId: null,
      actressId: null,
    },
    deleteOrderTarget: {
      id: 0,
      name: '',
    },
    deletingOrder: false,
    acceptMerchandiseOrderConfirmDialog: false,
    acceptMerchandiseOrderTarget: null,
    acceptEquipmentOrderConfirmDialog: false,
    acceptEquipmentOrderTarget: null,
    acceptingOrder: false,
  }),
  async created() {
    this.fetch();
  },
  async mounted() {
    this.$nextTick(() => {
      if (this.$route.query.category === 'merchandise') {
        this.orderPreset.scheduleId = Number(this.$route.query.scheduleId);
        this.orderPreset.actressId = Number(this.$route.query.actressId);
        setTimeout(() => {
          this.addMerchandiseOrderDialog = true;
        }, 500);
      }
    });
  },
  methods: {
    async fetch() {
      this.isLoading = true;
      this.orderItems = await OrderService.getOrders();
      this.isLoading = false;
    },
    async silentFetch() {
      this.orderItems = await OrderService.getOrders();
    },
    createOrder() {
      this.actionVisible = true;
    },
    createMerchandiseOrder() {
      this.actionVisible = false;
      this.addMerchandiseOrderDialog = true;
    },
    createEquipmentOrder() {
      this.actionVisible = false;
      this.addEquipmentOrderDialog = true;
    },
    async saveMerchandiseOrder(item) {
      await OrderService.createMerchandiseOrder(item);
      this.$store.dispatch('notification/set', {
        type: 'success',
        message: '発注情報を登録しました！',
      });
      await this.silentFetch();
    },
    async saveEquipmentOrder(item) {
      await OrderService.createEquipmentOrder(item);
      this.$store.dispatch('notification/set', {
        type: 'success',
        message: '発注情報を登録しました！',
      });
      await this.silentFetch();
    },

    confirmToRemoveOrder(item) {
      this.deleteOrderTarget.id = item.id;
      this.deleteOrderTarget.name = item.name;
      this.deleteOrderConfirmDialog = true;
    },

    async removeOrder() {
      this.deletingOrder = true;
      await OrderService.removeOrder(this.deleteOrderTarget.id);
      this.deletingOrder = false;
      this.deleteOrderTarget.id = 0;
      this.deleteOrderTarget.name = '';
      this.deleteOrderConfirmDialog = false;
      this.$store.dispatch('notification/set', {
        type: 'success',
        message: '発注情報を削除しました',
      });
      await this.silentFetch();
    },

    confirmToAcceptOrder(item) {
      if (item.category === 'merchandise') {
        this.confirmToAcceptMerchandiseOrder(item);
      } else if (item.category === 'equipment') {
        this.confirmToAcceptEquipmentOrder(item);
      }
    },

    confirmToAcceptMerchandiseOrder(item) {
      this.acceptMerchandiseOrderConfirmDialog = true;
      this.acceptMerchandiseOrderTarget = item;
    },

    confirmToAcceptEquipmentOrder(item) {
      this.acceptEquipmentOrderConfirmDialog = true;
      this.acceptEquipmentOrderTarget = item;
    },

    async acceptMerchandiseOrder(additionalInfo) {
      this.acceptingOrder = true;
      await OrderService.acceptMerchandiseOrder(this.acceptMerchandiseOrderTarget, additionalInfo);
      this.acceptingOrder = false;
      this.acceptMerchandiseOrderTarget = null;
      this.acceptMerchandiseOrderConfirmDialog = false;
      this.$store.dispatch('notification/set', {
        type: 'success',
        message: '物販に登録しました！',
      });
      await this.silentFetch();
    },

    async acceptEquipmentOrder(additionalInfo) {
      this.acceptingOrder = true;
      await OrderService.acceptEquipmentOrder(this.acceptEquipmentOrderTarget, additionalInfo);
      this.acceptingOrder = false;
      this.acceptEquipmentOrderTarget = null;
      this.acceptEquipmentOrderConfirmDialog = false;
      this.$store.dispatch('notification/set', {
        type: 'success',
        message: '備品リストに登録しました！',
      });
      await this.silentFetch();
    },
  },
};
</script>
