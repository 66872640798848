<template>
  <div class="my-4 bg-level-1 shadow-xl rounded-xl px-4 py-3">
    <div class="flex items-start justify-between">
      <div class="flex-grow">
        <span class="text-sub text-xxs tracking-widest">発注番号：{{ item.id }}</span>
        <h2 class="text-main font-bold">
          {{ item.name }}
        </h2>
      </div>
      <span
        class="text-xxs inline-block rounded-full px-3 py-1 flex-shrink-0 mt-1 bg-level-3"
        :class="{ merchandise: 'text-pink-400', equipment: 'text-blue-400' }[item.category]"
        >{{ { merchandise: '物販', equipment: '備品' }[item.category] }}</span
      >
    </div>
    <a
      v-if="item.url"
      :href="item.url"
      target="_blank"
      class="block w-full text-center text-sm text-white py-2 bg-cool-gray-800 rounded-lg my-2"
    >
      購入先のページに遷移 <font-awesome-icon class="ml-2" :icon="['fas', 'external-link-alt']" />
    </a>
    <p v-if="item.url" class="text-xxs text-sub text-opacity-50 pl-0.5">
      ※色等は表示されている物と異なる場合があります
    </p>

    <div class="border-t border-b border-cool-gray-800 divide-y divide-cool-gray-800 mt-4 mb-4">
      <div class="flex items-center px-2 py-1">
        <div class="text-sub text-xs w-20">
          ステータス
        </div>
        <div class="rounded-full w-2 h-2 mr-1" :class="statusColorClass[item.status]" />
        <div class="text-main text-xs flex-grow">
          {{
            { purchased: '発注済み', pending: '発注依頼中', rejected: '発注見合せ' }[item.status]
          }}
        </div>
      </div>
      <div class="flex items-center px-2 py-1">
        <div class="text-sub text-xs w-20">
          登録日
        </div>
        <div class="text-main text-xs flex-grow">
          {{ item.created_at | dateFormat }}
        </div>
      </div>
      <div class="flex items-center px-2 py-1">
        <div class="text-sub text-xs w-20">
          担当者
        </div>
        <div class="text-main text-xs flex-grow">
          {{ item.created_by.display_name }}
        </div>
      </div>
      <div v-if="item.schedule" class="flex items-center px-2 py-1">
        <div class="text-sub text-xs w-20">
          対象の撮影
        </div>
        <div class="text-main text-xs flex-grow">
          {{ item.schedule.title }}
        </div>
        <img
          :src="`/uploads/${item.schedule.thumbnailType}/${item.schedule.thumbnail}`"
          class="rounded-lg w-8 h-8 object-cover"
        />
      </div>
    </div>
    <div class="mb-2">
      <div v-if="item.created_by.id === $store.getters['user/id']" class="grid grid-cols-2 gap-2">
        <ui-button
          color="tertiary-error"
          class="w-full text-xs  text-red-500"
          rounded="lg"
          @click="$emit('click-remove')"
        >
          削除
        </ui-button>
        <ui-button
          color="primary"
          class="w-full text-xs"
          rounded="lg"
          @click="$emit('click-acceptance')"
        >
          検収
        </ui-button>
      </div>
      <div v-else>
        <ui-button
          color="primary"
          class="w-full text-xs"
          rounded="lg"
          @click="$emit('click-acceptance')"
        >
          検収
        </ui-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { format } from 'date-fns'

export default {
  name: 'OrderItem',
  filters: {
    dateFormat(date) {
      return format(new Date(date), 'yyyy年M月d日 HH:mm')
    },
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    imageUrl: '',
    uploading: false,
    cancelTokenSource: axios.CancelToken.source(),
    statusColorClass: {
      purchased: 'bg-green-600',
      pending: 'bg-yellow-500',
      rejected: 'bg-red-500',
    },
  }),
  async created() {
    if (this.item.url) {
      const ext = this.item.url
        .split(/[#?]/)[0]
        .split('.')
        .pop()
        .trim()
      if (['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG', 'gif', 'GIF'].includes(ext)) {
        this.imageUrl = this.item.url
        return
      }
      this.imageUrl = ''
    }
  },
  beforeDestroy() {
    this.cancelTokenSource.cancel()
  },
  // methods: {

  // },
}
</script>
